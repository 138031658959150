import React from "react"
import { Link, graphql } from "gatsby"

import Seo from "../../components/Seo"
import PageWrapper from "../../components/PageWrapper"

const BrandsPage = props => {
  const {
    data: {
      allBrand: { nodes: brands },
    },
  } = props

  return (
    <PageWrapper>
      <Seo title="Sélectionnez votre marque d'outils" lang="fr" />

      <main className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8 animate-fade-in">
        <div className="flex-shrink-0 py-4 sm:py-8 text-center">
          <h1 className="mt-2 mb-2 text-4xl font-extrabold tracking-tight sm:text-5xl">
            Marques
          </h1>

          <div>
            {brands?.map?.(brand => {
              return (
                <div key={brand.id}>
                  <Link
                    className="text-[2rem] leading-[52px]"
                    to={`/fr/${brand.uri}`}
                  >
                    {brand.name}
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </main>
    </PageWrapper>
  )
}

export default BrandsPage

export const pageQuery = graphql`
  query BrandsFr {
    allBrand {
      nodes {
        id
        uri
        name
      }
    }
  }
`
